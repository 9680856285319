const th = {
    alert: {
        reLogin: 'ที่อยู่เปลี่ยนแปลง กรุณาลงชื่อเข้าใช้อีกครั้ง',
        copied: 'คัดลอกสำเร็จ',
        connectWallet: 'กรุณาเชื่อมต่อกระเป๋าเงิน',
        notBound: 'ที่อยู่แนะนำยังไม่เชื่อมต่อ กรุณาเชื่อมต่อ',
        inputParent: 'กรุณาใส่ที่อยู่แนะนำ',
        addressError: 'กรุณาใส่ที่อยู่กระเป๋าเงินที่ถูกต้อง',
        parentError: 'ที่อยู่แนะนำไม่ถูกต้อง กรุณาใส่ใหม่',
        boundSuccess: 'เชื่อมต่อสำเร็จ',
        boundFailed: 'เชื่อมต่อไม่สำเร็จ กรุณาลองอีกครั้ง',
        success: 'สำเร็จ',
        failed: 'ล้มเหลว',
        soon: 'เปิดให้บริการเร็ว ๆ นี้...',
        insufficient: 'ยอดเงินไม่เพียงพอ',
        quotaInsufficient: 'โควต้าไม่เพียงพอ',
        amountError: 'จำนวนผิดพลาด',
        minAmountError: 'ขั้นต่ำ',
        redeemed: 'คำสั่งซื้อไม่มีอยู่หรือถูกแลกแล้ว',
        enter: 'ตกลง',
        congratulation: 'ขอแสดงความยินดีในการรวมกัน',
        walletInsufficient: 'ยอดเงินในกระเป๋าเงินไม่เพียงพอ',
        copySuccess: 'คัดลอกสำเร็จ'
    },
    header: {
        link: 'เชื่อมต่อกระเป๋าเงิน',
        home: 'หน้าแรก',
        lp: 'สเตค',
        award: 'รางวัล',
        solfi: 'SOLFI',
        user: 'โปรไฟล์ของฉัน',
        ido: 'IDO',
        inc: '© 2024 Arctic Intelligence Inc. สงวนลิขสิทธิ์ทั้งหมด.',
        bind: 'เชื่อมต่อ',
        bindPla: 'กรุณาใส่ที่อยู่ผู้แนะนำ',
        bindTitle: 'กรุณาเชื่อมต่อที่อยู่ผู้แนะนำ'
    },
    home: {
        title: 'แพลตฟอร์มสเตคแบบกระจายศูนย์',
        desc: 'STAKOS สร้างขึ้นจากสามเทคโนโลยีระดับโลกชั้นนำ — ป้อมดิจิทัล, การเทรด AI, และกลไกการสเตคซ้ำ เทคโนโลยีเหล่านี้ให้การสนับสนุนทางเทคนิคแก่โครงการ ซึ่งดึงดูดแฟน ๆ จากทั่วโลกและสนับสนุนความร่วมมือที่ใกล้ชิดกับสถาบันการเงินที่มีชื่อเสียง',
        lp: 'สเตค',
        advantageTitle1: 'ป้อมดิจิทัล',
        advantageDesc1: 'การป้องกันหลายชั้น, กลยุทธ์ความปลอดภัยขั้นสูง, และกลไกการป้องกันอัจฉริยะ เพื่อให้มั่นใจในความปลอดภัยของข้อมูลและจัดการกับภัยคุกคามทางไซเบอร์ได้อย่างราบรื่น',
        advantageTitle2: 'การเทรด AI',
        advantageDesc2: 'ด้วยเทคโนโลยี AI ที่ล้ำสมัย การเทรด AI สามารถปรับกลยุทธ์การเทรดได้อัตโนมัติ ตอบสนองต่อการเปลี่ยนแปลงของตลาดได้อย่างรวดเร็ว และลดผลกระทบจากอารมณ์ของมนุษย์ต่อการตัดสินใจในการเทรด จึงเพิ่มประสิทธิภาพและผลตอบแทนการลงทุน',
        advantageTitle3: 'กลไกการสเตคซ้ำ',
        advantageDesc3: 'เพิ่มประสิทธิภาพของทุน อนุญาตให้มีการสเตคซ้ำหลายครั้ง; เพิ่มความปลอดภัยของเครือข่าย สนับสนุนหลายโปรโตคอล; มอบรางวัลเพิ่มเติมและกระตุ้นให้ผู้สเตคยอมรับความเสี่ยงมากขึ้น; การจัดการอัตโนมัติผ่านสัญญาอัจฉริยะ กลไกนี้ใช้สินทรัพย์ที่สเตคแล้วเพื่อสนับสนุนโปรโตคอลอื่น ๆ และเพิ่มการแบ่งปันทรัพยากรและความปลอดภัย',
        gain: 'ประโยชน์หลัก',
        gainTitle1: 'ผลกำไรสูง',
        gainDesc1: 'ผู้ใช้สามารถสเตค USDT เพื่อรับผลกำไรสูงและเพิ่มมูลค่าทรัพย์สิน',
        gainTitle2: 'การลงทุนที่หลากหลาย',
        gainDesc2: 'STAKOS ช่วยให้ผู้ใช้สามารถเข้าร่วมการสเตคและการขุดสภาพคล่องได้ในที่เดียว ลดความเสี่ยง',
        gainTitle3: 'ระบบนิเวศที่เปิดกว้าง',
        gainDesc3: 'ในฐานะส่วนหนึ่งของระบบนิเวศที่เปิดกว้าง STAKOS จะขยายตัวและเพิ่มโปรโตคอล DeFi ชั้นนำมากขึ้น ให้บริการทางการเงินและโอกาสในการสร้างรายได้ที่หลากหลายแก่ผู้ใช้',
        friendTitle: 'พันธมิตร',
        friendDesc: 'กลยุทธ์พูลการสเตคของ STAKOS ได้รับการสนับสนุนโดย Seven Seas ซึ่งเป็นหนึ่งในผู้สร้างพูลการสเตคชั้นนำในด้าน DeFi ความร่วมมือกับ Seven Seas ทำให้ STAKOS สามารถให้บริการสเตคที่ปลอดภัยและเชื่อถือได้ และปรับกลยุทธ์การสเตคอย่างต่อเนื่องเพื่อให้ได้ผลตอบแทนที่สูงขึ้น'
    },
    pay: {
        desc: 'ปกป้องอนาคตของคุณด้วย Solana – กระเป๋าเงินที่เชื่อถือได้ พร้อมการทำธุรกรรมที่รวดเร็วอย่างไฟฟ้า!',
        days: 'วัน',
        hours: 'ชั่วโมง',
        minutes: 'นาที',
        seconds: 'วินาที',
        android: 'ดาวน์โหลดสำหรับ Android',
        ios: 'ดาวน์โหลดจาก App Store'
    },
    lp: {
        title: 'การสเตค',
        title1: 'STAKOS ทำให้การสเตค USDT ง่ายขึ้น โดยมอบคุณค่าและผลตอบแทนที่สำคัญต่อผู้ลงทุนและสมาชิกในชุมชน',
        stackDesc: 'SolFi ทำให้การสเตค SOL ง่ายขึ้น โดยมอบคุณค่าและผลตอบแทนที่สำคัญต่อผู้ลงทุนและสมาชิกในชุมชน',
        stackList1: 'การสเตคของฉัน',
        stackList2: 'ที่ได้รับ',
        stackList3: 'ที่ได้รับจากการทบต้น',
        lp: 'การสเตค',
        underway: 'การสเตคกำลังดำเนินการ',
        earn: 'รายได้ที่ได้รับ',
        yet: 'รายได้ที่สามารถถอนออกได้',
        redeem: 'แลก',
        yetBut: 'ถอน',
        accredit: 'ให้สิทธิ์',
        record: 'บันทึกการสเตค',
        tokenTitle: 'ผลตอบแทนจากการทบต้น',
        tokenDesc: 'รายได้ที่ยังคงอยู่ในสเตคจะถูกทบต้นอัตโนมัติ โดยการแจกจ่ายโทเคน SOLFI ตามอัตรา 1 SOLFI = 1 SOL โทเคน SolFi (SOLFI) จะเป็นสกุลเงินหลักของแพลตฟอร์มทั้งหมด ทำให้สามารถเข้าถึงบริการ AI และการบริหารชุมชน และจะมีค่ามากในอนาคต',
        tokenList1: 'สิทธิ์ในการทบต้น',
        tokenList2: 'ผลตอบแทนจากการทบต้นที่ได้รับ',
        tokenList3: 'ผลตอบแทนจากการทบต้นที่สามารถถอนออกได้',
        yes: 'ใช่',
        no: 'ไม่',
        back: 'กลับไปที่การสเตค',
        money: 'จำนวน',
        timeStart: 'เวลาที่เริ่มสเตค',
        timeEnd: 'เวลาหมดอายุ',
        cancel: 'ยกเลิกการต่ออายุอัตโนมัติ',
        more: 'เพิ่มเติม...',
        max: 'สูงสุด',
        quotaRemain: 'ยอดคงเหลือ',
        usable: 'สามารถสเตคได้',
        redeemed: 'แลกแล้ว',
        redeeming: 'กำลังแลก...',
        staking: 'การสเตคกำลังดำเนินการ...'
    },
    award: {
        communityDesc: 'สมาชิกในชุมชนที่มีส่วนร่วมในการพัฒนาจะได้รับรางวัลพิเศษ',
        communityList1: 'รางวัลที่ได้รับ',
        communityList2: 'ที่ถอนได้',
        communityList3: 'ที่สามารถถอนได้',
        communityBut: 'ถอน',
        record: 'บันทึกรางวัล',
        listType1: 'รางวัลการแนะนำ',
        listType2: 'รางวัลทีม',
        listType3: 'รางวัลความเท่าเทียม',
        listType4: 'รางวัลปันผล',
        total: 'รางวัลรวม'
    },
    top: {
        tokenDesc: 'โทเคน SolFi (SOLFI) จะเป็นสกุลเงินหลักของแพลตฟอร์มทั้งหมด ทำให้สามารถเข้าถึงบริการ AI และการบริหารชุมชน และจะมีค่ามากในอนาคต',
        price: 'ราคา',
        allAmount: 'ปริมาณทั้งหมด',
        market: 'มูลค่าตลาด',
        my: 'SOLFI ของฉัน',
        balance: 'ยอดเงินในกระเป๋าเงิน',
        buy: 'Swap',
        approve: 'อนุมัติ SOL',
        approveTop: 'อนุมัติ SOLFI',
        earn: 'ที่ได้รับ',
        getYet: 'ที่สามารถรับได้',
        get: 'รับ'
    },
    ido: {
        turn: 'รอบปัจจุบัน',
        all: 'ปริมาณทั้งหมดของ IDO',
        price: 'ราคา',
        limit: 'วงเงินการซื้อ',
        ps: 'วิธีการปลดล็อก: ปิดล็อก [] เดือน จากนั้นปลดล็อกแบบเชิงเส้นเป็นเวลา 12 เดือน',
        my: 'วงเงินของฉัน',
        nobuy: 'ยังไม่ได้ซื้อ',
        balance: 'ยอดเงิน',
        custom: 'คาดว่าจะได้รับ',
        buy: 'ซื้อทันที',
        buyYet: 'ซื้อแล้ว',
        record: 'บันทึกการซื้อ',
        buyTrun: 'รอบการซื้อ',
        buyUsdt: 'ซื้อ SOL',
        getTop: 'รับ SOLFI',
        unlock: 'เวลาถัดไปที่ปลดล็อก',
        unlockYet: 'SOLFI ที่ปลดล็อกแล้ว',
        claimable: 'SOLFI ที่สามารถรับได้',
        get: 'รับ',
        nodataText: 'ไม่มีข้อมูลการซื้อ'
    },
    user: {
        desc: 'แชร์ STAKOS และช่วยเหลือผู้ที่ต้องการความช่วยเหลือ โดยการสร้างรายได้ที่สำคัญในด้านบล็อกเชน',
        joinTime: 'วันที่สมัครสมาชิก',
        memberTeam: 'สมาชิกทีม',
        memberDirect: 'ผู้แนะนำตรง',
        teamLp: 'การสเตคของทีม',
        myLink: 'ลิงค์แนะนำของฉัน',
        myTeam: 'ทีมของฉัน',
        personage: 'การสเตคส่วนบุคคล',
        cur: 'ปัจจุบัน',
        lookDown: 'ดูสมาชิกในระดับล่าง',
        copy: 'คัดลอก',
        info: 'ข้อมูลส่วนบุคคล'
    }
};

export default th;
