const id = {
    alert: {
        reLogin: 'Alamat telah berubah, silakan login kembali',
        copied: 'Berhasil disalin',
        connectWallet: 'Silakan sambungkan dompet',
        notBound: 'Alamat sponsor belum terikat, silakan ikat',
        inputParent: 'Silakan masukkan alamat sponsor',
        addressError: 'Silakan masukkan alamat dompet yang benar',
        parentError: 'Alamat sponsor salah, silakan masukkan kembali',
        boundSuccess: 'Ikatan berhasil',
        boundFailed: 'Ikatan gagal, silakan coba lagi',
        success: 'Berhasil',
        failed: 'Gagal',
        soon: 'Akan dibuka segera...',
        insufficient: 'Saldo tidak cukup',
        quotaInsufficient: 'Kuota tidak cukup',
        amountError: 'Jumlah tidak benar',
        minAmountError: 'Minimum',
        redeemed: 'Pesanan tidak ada atau sudah ditebus',
        enter: 'Konfirmasi',
        congratulation: 'Selamat Anda telah berhasil menyusun',
        walletInsufficient: 'Saldo dompet tidak cukup',
        copySuccess: 'Berhasil disalin'
    },
    header: {
        link: 'Sambungkan dompet',
        home: 'Beranda',
        lp: 'Staking',
        award: 'Hadiah',
        solfi: 'SOLFI',
        user: 'Akun Saya',
        ido: 'IDO',
        inc: '© 2024 Arctic Intelligence Inc. Semua hak dilindungi.',
        bind: 'Ikat',
        bindPla: 'Silakan masukkan alamat orang yang mengundang',
        bindTitle: 'Silakan ikat alamat orang yang mengundang'
    },
    home: {
        title: 'Platform Staking Terdesentralisasi',
        desc: 'STAKOS didasarkan pada tiga teknologi terkemuka global — Benteng Digital, Perdagangan AI, dan Mekanisme staking ulang, yang menyediakan dukungan teknis untuk proyek. Dengan demikian, STAKOS telah menarik penggemar dari seluruh dunia untuk berpartisipasi dan mendapatkan kerjasama erat dari lembaga keuangan terkemuka.',
        lp: 'Staking',
        advantageTitle1: 'Benteng Digital',
        advantageDesc1: 'Perlindungan tinggi, strategi keamanan multi-lapis, mekanisme pertahanan cerdas, memastikan data tetap aman dan mampu menangani ancaman jaringan dengan efektif.',
        advantageTitle2: 'Perdagangan AI',
        advantageDesc2: 'Dengan teknologi AI canggih, perdagangan AI dapat mengoptimalkan strategi perdagangan secara otomatis, merespons perubahan pasar dengan cepat, dan mengurangi dampak emosi manusia terhadap keputusan perdagangan, sehingga meningkatkan efisiensi perdagangan dan keuntungan investasi.',
        advantageTitle3: 'Mekanisme staking ulang',
        advantageDesc3: 'Meningkatkan efisiensi modal, memungkinkan staking beberapa kali; meningkatkan keamanan jaringan, mendukung berbagai protokol; memberikan imbalan tambahan dan mendorong pemangku kepentingan untuk mengambil lebih banyak risiko; mengelola secara otomatis melalui kontrak pintar. Mekanisme ini menggunakan aset yang telah distaking untuk mendukung protokol lain, mencapai berbagi sumber daya dan meningkatkan keamanan.',
        gain: 'Manfaat Utama',
        gainTitle1: 'Keuntungan Tinggi',
        gainDesc1: 'Pengguna dapat melakukan staking aset USDT untuk mendapatkan keuntungan tinggi, meningkatkan nilai aset',
        gainTitle2: 'Investasi Beragam',
        gainDesc2: 'STAKOS memungkinkan pengguna untuk berpartisipasi dalam staking dan mining likuiditas secara bersamaan, mengurangi risiko',
        gainTitle3: 'Ekosistem Terbuka',
        gainDesc3: 'Sebagai bagian dari ekosistem terbuka, STAKOS akan terus berkembang dan menambahkan protokol DeFi unggulan, menyediakan layanan keuangan dan peluang keuntungan yang lebih luas untuk pengguna.',
        friendTitle: 'Mitra',
        friendDesc: 'Strategi pool staking STAKOS disediakan oleh Seven Seas, salah satu pembangun pool staking terkemuka di bidang DeFi. Melalui kerjasama dengan Seven Seas, STAKOS dapat menyediakan layanan staking yang aman dan terpercaya, serta terus mengoptimalkan strategi staking untuk mencapai keuntungan yang lebih tinggi.'
    },
    pay: {
        desc: 'Lindungi masa depan Anda dengan Solana – Dompet terpercaya dengan transaksi secepat kilat!',
        days: 'Hari',
        hours: 'Jam',
        minutes: 'Menit',
        seconds: 'Detik',
        android: 'Unduh untuk Android',
        ios: 'Unduh dari App Store'
    },
    lp: {
        title: 'Staking',
        title1: 'STAKOS membuat staking USDT menjadi lebih mudah, menyediakan nilai terus-menerus dan imbalan menarik bagi investor dan anggota komunitas.',
        stackDesc: 'SolFi membuat staking SOL menjadi lebih mudah, menyediakan nilai terus-menerus dan imbalan menarik bagi investor dan anggota komunitas.',
        stackList1: 'Staking Saya',
        stackList2: 'Telah Didapat',
        stackList3: 'Laba dari Bunga Majemuk',
        lp: 'Staking',
        underway: 'Sedang staking',
        earn: 'Keuntungan yang telah didapat',
        yet: 'Keuntungan yang dapat diambil',
        redeem: 'Tebus',
        yetBut: 'Ambil',
        accredit: 'Berikan izin',
        record: 'Catatan staking',
        tokenTitle: 'Keuntungan dari Bunga Majemuk',
        tokenDesc: 'Keuntungan dari staking yang tidak diambil akan menghasilkan bunga majemuk, mendistribusikan token SOLFI dengan rasio 1 SOLFI = 1 SOL. Token SolFi (SOLFI) akan menjadi token fungsional untuk seluruh platform, memungkinkan pengalaman layanan AI terintegrasi dan tata kelola komunitas, yang akan memiliki nilai tinggi di masa depan.',
        tokenList1: 'Dapatkah mendapatkan bunga majemuk',
        tokenList2: 'Keuntungan bunga majemuk yang diperoleh',
        tokenList3: 'Keuntungan bunga majemuk yang dapat diambil',
        yes: 'Ya',
        no: 'Tidak',
        back: 'Kembali ke staking',
        money: 'Jumlah',
        timeStart: 'Waktu mulai staking',
        timeEnd: 'Waktu kedaluwarsa',
        cancel: 'Batalkan perpanjangan otomatis',
        more: 'Lebih banyak...',
        max: 'Maksimal',
        quotaRemain: 'Saldo',
        usable: 'Dapat di-stake',
        redeemed: 'Telah ditebus',
        redeeming: 'Sedang ditebus...',
        staking: 'Sedang staking...'
    },
    award: {
        communityDesc: 'Anggota komunitas yang berkontribusi pada pengembangan akan menerima hadiah khusus komunitas.',
        communityList1: 'Hadiah yang diterima',
        communityList2: 'Telah diambil',
        communityList3: 'Dapat diambil',
        communityBut: 'Ambil',
        record: 'Catatan hadiah',
        listType1: 'Hadiah Rekomendasi Langsung',
        listType2: 'Hadiah Tim',
        listType3: 'Hadiah Setara',
        listType4: 'Hadiah Dividen',
        total: 'Total Hadiah'
    },
    top: {
        tokenDesc: 'Token SolFi (SOLFI) akan menjadi token fungsional untuk seluruh platform, memungkinkan pengalaman layanan AI terintegrasi dan tata kelola komunitas, yang akan memiliki nilai tinggi di masa depan.',
        price: 'Harga',
        allAmount: 'Total Pasokan',
        market: 'Kapitalisasi Pasar',
        my: 'SOLFI Saya',
        balance: 'Saldo Dompet',
        buy: 'Swap',
        approve: 'Persetujuan SOL',
        approveTop: 'Persetujuan SOLFI',
        earn: 'Telah Diperoleh',
        getYet: 'Dapat Diperoleh',
        get: 'Dapatkan'
    },
    ido: {
        turn: 'Putaran Saat Ini',
        all: 'Jumlah Total IDO',
        price: 'Harga',
        limit: 'Batas Pembelian',
        ps: 'Metode Pembukaan Kunci: Terkunci [] bulan, kemudian dibuka secara linier selama 12 bulan',
        my: 'Batas Saya',
        nobuy: 'Belum dibeli',
        balance: 'Saldo',
        custom: 'Diperkirakan Dapat Diperoleh',
        buy: 'Beli Sekarang',
        buyYet: 'Telah Dibeli',
        record: 'Catatan Pembelian',
        buyTrun: 'Putaran Pembelian',
        buyUsdt: 'Beli SOL',
        getTop: 'Dapatkan SOLFI',
        unlock: 'Waktu Pembukaan Kunci Berikutnya',
        unlockYet: 'SOLFI yang Sudah Dibuka',
        claimable: 'SOLFI yang Dapat Diperoleh',
        get: 'Ambil',
        nodataText: 'Tidak ada catatan pembelian'
    },
    user: {
        desc: 'Bagikan STAKOS dan bantu orang yang membutuhkan, sambil mendapatkan keuntungan signifikan di bidang blockchain.',
        joinTime: 'Waktu Daftar',
        memberTeam: 'Anggota Tim',
        memberDirect: 'Anggota Rekomendasi Langsung',
        teamLp: 'Staking Tim',
        myLink: 'Tautan Undangan Saya',
        myTeam: 'Tim Saya',
        personage: 'Staking Pribadi',
        cur: 'Saat Ini',
        lookDown: 'Lihat Bawahan',
        copy: 'Salin',
        info: 'Informasi Pribadi'
    }
};

export default id;
