const ja = {
    alert: {
        reLogin: 'アドレスが変更されました。再度ログインしてください',
        copied: 'コピーに成功しました',
        connectWallet: 'ウォレットを接続してください',
        notBound: '推薦者アドレスが未登録です。登録してください',
        inputParent: '推薦者アドレスを入力してください',
        addressError: '正しいウォレットアドレスを入力してください',
        parentError: '推薦者アドレスが間違っています。再入力してください',
        boundSuccess: '登録に成功しました',
        boundFailed: '登録に失敗しました。もう一度お試しください',
        success: '成功',
        failed: '失敗',
        soon: 'まもなく開始...',
        insufficient: '残高不足',
        quotaInsufficient: '枠が不足しています',
        amountError: '金額エラー',
        minAmountError: '最小',
        redeemed: '注文が存在しないか、すでに引き換え済みです',
        enter: '確認',
        congratulation: '合成に成功しました',
        walletInsufficient: 'ウォレット残高が不足しています',
        copySuccess: 'コピーに成功しました'
    },
    header: {
        link: 'ウォレットを接続',
        home: 'ホーム',
        lp: 'ステーキング',
        award: '報酬',
        solfi: 'SOLFI',
        user: 'マイページ',
        ido: 'IDO',
        inc: '© 2024 Arctic Intelligence Inc. 全著作権所有。',
        bind: '登録',
        bindPla: '招待者のアドレスを入力してください',
        bindTitle: '招待者アドレスを登録してください'
    },
    home: {
        title: '分散型ステーキングプラットフォーム',
        desc: 'STAKOSは、デジタルフォートレス、AI取引、再ステーキングメカニズムという3つの世界最高峰の技術を基盤としています。これらの技術により、STAKOSは世界中のファンを魅了し、権威ある金融機関との密接な協力を得ています。',
        lp: 'ステーキング',
        advantageTitle1: "デジタルフォートレス",
        advantageDesc1: '高強度の防御、多層的なセキュリティ戦略、インテリジェントな防御メカニズムにより、データの安全性を確保し、ネットワークの脅威に対応します。',
        advantageTitle2: "AI取引",
        advantageDesc2: '高度なAI技術により、AI取引は自動的に取引戦略を最適化し、市場の変化に迅速に対応します。また、人為的な感情の影響を最小限に抑え、取引の効率と投資利益を向上させます。',
        advantageTitle3: "再ステーキングメカニズム",
        advantageDesc3: '資本効率を向上させ、資産の再ステーキングを可能にします。ネットワークのセキュリティを強化し、複数のプロトコルをサポート。リスクを負うステーキング者に追加の報酬を提供し、スマートコントラクトによる自動管理を実現。ステーキング資産を他のプロトコルで活用し、リソース共有とセキュリティを強化します。',
        gain: '主な利益',
        gainTitle1: '高利益',
        gainDesc1: 'ユーザーはUSDTをステーキングすることで、高い利益を得て資産の増加を図ることができます。',
        gainTitle2: '多様な投資',
        gainDesc2: 'STAKOSは、ユーザーがステーキングと流動性マイニングに一度に参加でき、資産の多様化を図りリスクを低減します。',
        gainTitle3: 'オープンなエコシステム',
        gainDesc3: 'STAKOSは、オープンなエコシステムの一部として、さらに多くの優れたDeFiプロトコルに接続し、ユーザーに幅広い金融サービスと収益機会を提供します。',
        friendTitle: 'パートナー',
        friendDesc: 'STAKOSのステーキングプール戦略は、DeFi業界のトップであるSeven Seasによって提供されています。Seven Seasとの提携により、STAKOSは安全で信頼できるステーキングサービスを提供し、戦略を最適化してより高い収益を実現します。'
    },
    pay: {
        desc: 'Solanaであなたの未来を守りましょう - 信頼できるウォレットで、超高速トランザクションが可能です！',
        days: '日',
        hours: '時間',
        minutes: '分',
        seconds: '秒',
        android: 'Androidダウンロード',
        ios: 'APP Storeダウンロード'
    },
    lp: {
        title: 'ステーキング',
        title1: 'STAKOSはUSDTステーキングを簡単にし、投資家やコミュニティメンバーに継続的な価値と魅力的なリターンを提供します。',
        stackDesc: 'SolFiはSOLステーキングを簡単にし、投資家やコミュニティメンバーに継続的な価値と魅力的なリターンを提供します。',
        stackList1: 'マイステーキング',
        stackList2: '獲得済み',
        stackList3: '複利で得た',
        lp: 'ステーキング',
        underway: 'ステーキング中',
        earn: '獲得済みの利益',
        yet: '引き出せる利益',
        redeem: '引き換え',
        yetBut: '引き出し',
        accredit: '認証',
        record: 'ステーキング履歴',
        tokenTitle: '複利利益',
        tokenDesc: 'ステーキングで得た利益を引き出さない場合、複利利益が発生します。1SOLFI = 1SOLの割合でSOLFIトークンが付与されます。SolFiトークン(SOLFI)は、プラットフォーム全体の機能トークンとして使用され、AIサービスやコミュニティガバナンスに参加でき、将来的に大きな価値を持つことが期待されています。',
        tokenList1: '複利を享受できますか',
        tokenList2: '獲得した複利利益',
        tokenList3: '引き出せる複利利益',
        yes: 'はい',
        no: 'いいえ',
        back: 'ステーキングに戻る',
        money: '金額',
        timeStart: 'ステーキング開始時間',
        timeEnd: '終了時間',
        cancel: '自動更新のキャンセル',
        more: 'もっと見る...',
        max: '最大',
        quotaRemain: '残高',
        usable: 'ステーキング可能',
        redeemed: '引き換え済み',
        redeeming: '引き換え中...',
        staking: 'ステーキング中...'
    },
    award: {
        communityDesc: 'コミュニティの発展に貢献したメンバーは、特別なコミュニティ報酬を受け取ります。',
        communityList1: '獲得した報酬',
        communityList2: '引き出した報酬',
        communityList3: '引き出せる報酬',
        communityBut: '引き出し',
        record: '報酬履歴',
        listType1: '直接推薦報酬',
        listType2: 'チーム報酬',
        listType3: '同ランク報酬',
        listType4: '分配報酬',
        total: '総報酬'
    },
    top: {
        tokenDesc: 'SolFiトークン(SOLFI)は、プラットフォーム全体の機能トークンとして使用され、AIサービスやコミュニティガバナンスに参加でき、将来的に大きな価値を持つことが期待されています。',
        price: '価格',
        allAmount: '総供給量',
        market: '市場価値',
        my: '私のSOLFI',
        balance: 'ウォレット残高',
        buy: 'スワップ',
        approve: 'SOLを承認',
        approveTop: 'SOLFIを承認',
        earn: '獲得済み',
        getYet: '引き出せる',
        get: '引き出し'
    },
    ido: {
        turn: '現在のラウンド',
        all: 'IDO総量',
        price: '価格',
        limit: 'サブスクリプション限度額',
        ps: 'ロック解除方法：[]ヶ月後にロック解除され、12ヶ月間の線形解除が行われます。',
        my: '私のサブスクリプション',
        nobuy: '未サブスクリプション',
        balance: '残高',
        custom: '予想される獲得量',
        buy: '今すぐサブスクリプション',
        buyYet: 'サブスクリプション済み',
        record: 'サブスクリプション履歴',
        buyTrun: 'サブスクリプションラウンド',
        buyUsdt: 'SOLをサブスクリプション',
        getTop: 'SOLFIを獲得',
        unlock: '次のロック解除時間',
        unlockYet: 'ロック解除されたSOLFI',
        claimable: '引き出せるSOLFI',
        get: '引き出し',
        nodataText: 'サブスクリプション履歴がありません'
    },
    user: {
        desc: 'STAKOSを共有し、より多くの人々を支援し、ブロックチェーン分野で高いリターンを得ましょう。',
        joinTime: '登録時間',
        memberTeam: 'チームメンバー',
        memberDirect: '直接推薦メンバー',
        teamLp: 'チームステーキング',
        myLink: '私の招待リンク',
        myTeam: '私のチーム',
        personage: '個人ステーキング',
        cur: '現在',
        lookDown: '下位を表示',
        copy: 'コピー',
        info: '個人情報'
    }
}

export default ja;
