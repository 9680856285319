const vi = {
    alert: {
        reLogin: 'Địa chỉ đã thay đổi, vui lòng đăng nhập lại',
        copied: 'Sao chép thành công',
        connectWallet: 'Vui lòng kết nối ví',
        notBound: 'Chưa liên kết địa chỉ người giới thiệu, vui lòng liên kết',
        inputParent: 'Vui lòng nhập địa chỉ người giới thiệu',
        addressError: 'Vui lòng nhập địa chỉ ví chính xác',
        parentError: 'Địa chỉ người giới thiệu không chính xác, vui lòng nhập lại',
        boundSuccess: 'Liên kết thành công',
        boundFailed: 'Liên kết không thành công, vui lòng thử lại',
        success: 'Thành công',
        failed: 'Thất bại',
        soon: 'Sẽ mở sớm...',
        insufficient: 'Số dư không đủ',
        quotaInsufficient: 'Hạn mức không đủ',
        amountError: 'Số tiền không đúng',
        minAmountError: 'Tối thiểu',
        redeemed: 'Đơn hàng không tồn tại hoặc đã được đổi',
        enter: 'Xác nhận',
        congratulation: 'Chúc mừng bạn đã hợp thành',
        walletInsufficient: 'Số dư ví không đủ',
        copySuccess: 'Sao chép thành công'
    },
    header: {
        link: 'Kết nối ví',
        home: 'Trang chủ',
        lp: 'Staking',
        award: 'Phần thưởng',
        solfi: 'SOLFI',
        user: 'Tài khoản của tôi',
        ido: 'IDO',
        inc: '© 2024 Arctic Intelligence Inc. Bảo lưu mọi quyền.',
        bind: 'Liên kết',
        bindPla: 'Vui lòng nhập địa chỉ người mời',
        bindTitle: 'Vui lòng liên kết địa chỉ người mời'
    },
    home: {
        title: 'Nền tảng Staking phi tập trung',
        desc: 'STAKOS dựa trên ba công nghệ hàng đầu toàn cầu — Pháo đài số, Giao dịch AI và Cơ chế staking lại, các công nghệ này cung cấp hỗ trợ kỹ thuật cho dự án. Do đó, STAKOS đã thu hút người hâm mộ từ toàn cầu tham gia và nhận được sự hợp tác chặt chẽ từ các tổ chức tài chính uy tín.',
        lp: 'Staking',
        advantageTitle1: 'Pháo đài số',
        advantageDesc1: 'Bảo vệ cao, chiến lược an toàn đa lớp, cơ chế phòng thủ thông minh, đảm bảo dữ liệu an toàn và đối phó với các mối đe dọa mạng một cách hiệu quả.',
        advantageTitle2: 'Giao dịch AI',
        advantageDesc2: 'Với công nghệ AI tiên tiến, giao dịch AI có thể tối ưu hóa chiến lược giao dịch tự động, phản ứng nhanh chóng với biến động thị trường và giảm ảnh hưởng của cảm xúc con người đối với quyết định giao dịch, từ đó nâng cao hiệu quả giao dịch và lợi nhuận đầu tư.',
        advantageTitle3: 'Cơ chế staking lại',
        advantageDesc3: 'Tăng hiệu quả vốn, cho phép staking nhiều lần; nâng cao an ninh mạng, hỗ trợ nhiều giao thức; cung cấp phần thưởng bổ sung và khuyến khích người staking chấp nhận nhiều rủi ro hơn; quản lý tự động thông qua hợp đồng thông minh. Cơ chế này sử dụng tài sản đã staking để hỗ trợ các giao thức khác, đạt được sự chia sẻ tài nguyên và nâng cao tính an toàn.',
        gain: 'Lợi ích chính',
        gainTitle1: 'Lợi nhuận cao',
        gainDesc1: 'Người dùng có thể staking tài sản USDT để kiếm lợi nhuận cao, gia tăng giá trị tài sản',
        gainTitle2: 'Đầu tư đa dạng',
        gainDesc2: 'STAKOS cho phép người dùng tham gia staking và khai thác thanh khoản một cách đồng bộ, giảm rủi ro',
        gainTitle3: 'Hệ sinh thái mở',
        gainDesc3: 'Là một phần của hệ sinh thái mở, STAKOS sẽ liên tục mở rộng và thêm các giao thức DeFi hàng đầu, cung cấp dịch vụ tài chính và cơ hội kiếm lợi nhuận rộng rãi hơn cho người dùng.',
        friendTitle: 'Đối tác',
        friendDesc: 'Chiến lược hồ staking của STAKOS được cung cấp bởi Seven Seas, một trong những nhà xây dựng hồ staking hàng đầu trong lĩnh vực DeFi. Thông qua sự hợp tác với Seven Seas, STAKOS có thể cung cấp dịch vụ staking an toàn và đáng tin cậy, đồng thời tối ưu hóa chiến lược staking để đạt được lợi nhuận cao hơn.'
    },
    pay: {
        desc: 'Bảo vệ tương lai của bạn với Solana – Ví đáng tin cậy với giao dịch nhanh như điện!',
        days: 'Ngày',
        hours: 'Giờ',
        minutes: 'Phút',
        seconds: 'Giây',
        android: 'Tải xuống cho Android',
        ios: 'Tải xuống từ App Store'
    },
    lp: {
        title: 'Staking',
        title1: 'STAKOS làm cho việc staking USDT trở nên đơn giản hơn, cung cấp giá trị liên tục và lợi nhuận hấp dẫn cho các nhà đầu tư và thành viên cộng đồng.',
        stackDesc: 'SolFi làm cho việc staking SOL trở nên đơn giản hơn, cung cấp giá trị liên tục và lợi nhuận hấp dẫn cho các nhà đầu tư và thành viên cộng đồng.',
        stackList1: 'Staking của tôi',
        stackList2: 'Đã kiếm được',
        stackList3: 'Lợi nhuận từ lãi kép',
        lp: 'Staking',
        underway: 'Đang staking',
        earn: 'Lợi nhuận đã kiếm được',
        yet: 'Lợi nhuận có thể rút',
        redeem: 'Đổi',
        yetBut: 'Rút',
        accredit: 'Cấp quyền',
        record: 'Lịch sử staking',
        tokenTitle: 'Lợi nhuận từ lãi kép',
        tokenDesc: 'Lợi nhuận từ staking không được rút sẽ được tính lãi kép, phân phối token SOLFI theo tỷ lệ 1 SOLFI = 1 SOL. Token SolFi (SOLFI) sẽ là token chức năng của toàn bộ nền tảng, có thể tham gia trải nghiệm dịch vụ AI tích hợp và quản trị cộng đồng, sẽ có giá trị cao trong tương lai.',
        tokenList1: 'Có được lãi kép không',
        tokenList2: 'Lợi nhuận từ lãi kép đã nhận',
        tokenList3: 'Lợi nhuận từ lãi kép có thể rút',
        yes: 'Có',
        no: 'Không',
        back: 'Quay lại staking',
        money: 'Số tiền',
        timeStart: 'Thời gian bắt đầu staking',
        timeEnd: 'Thời gian hết hạn',
        cancel: 'Hủy tự động gia hạn',
        more: 'Thêm...',
        max: 'Tối đa',
        quotaRemain: 'Số dư',
        usable: 'Có thể staking',
        redeemed: 'Đã đổi',
        redeeming: 'Đang đổi...',
        staking: 'Đang staking...'
    },
    award: {
        communityDesc: 'Các thành viên trong cộng đồng có đóng góp cho sự phát triển sẽ nhận được phần thưởng đặc biệt.',
        communityList1: 'Phần thưởng đã nhận',
        communityList2: 'Đã rút',
        communityList3: 'Có thể rút',
        communityBut: 'Rút',
        record: 'Lịch sử phần thưởng',
        listType1: 'Phần thưởng giới thiệu trực tiếp',
        listType2: 'Phần thưởng nhóm',
        listType3: 'Phần thưởng ngang hàng',
        listType4: 'Phần thưởng cổ tức',
        total: 'Tổng phần thưởng'
    },
    top: {
        tokenDesc: 'Token SolFi (SOLFI) sẽ là token chức năng của toàn bộ nền tảng, có thể tham gia trải nghiệm dịch vụ AI tích hợp và quản trị cộng đồng, sẽ có giá trị cao trong tương lai.',
        price: 'Giá',
        allAmount: 'Tổng cung',
        market: 'Vốn hóa thị trường',
        my: 'SOLFI của tôi',
        balance: 'Số dư ví',
        buy: 'Swap',
        approve: 'Phê duyệt SOL',
        approveTop: 'Phê duyệt SOLFI',
        earn: 'Đã kiếm được',
        getYet: 'Có thể nhận',
        get: 'Nhận'
    },
    ido: {
        turn: 'Vòng hiện tại',
        all: 'Tổng số IDO',
        price: 'Giá',
        limit: 'Hạn mức mua',
        ps: 'Phương pháp mở khóa: Khóa [] tháng, sau đó mở khóa theo tỷ lệ tuyến tính trong 12 tháng',
        my: 'Hạn mức của tôi',
        nobuy: 'Chưa mua',
        balance: 'Số dư',
        custom: 'Dự kiến nhận được',
        buy: 'Đăng ký ngay',
        buyYet: 'Đã đăng ký',
        record: 'Lịch sử đăng ký',
        buyTrun: 'Vòng đăng ký',
        buyUsdt: 'Mua SOL',
        getTop: 'Nhận SOLFI',
        unlock: 'Thời gian mở khóa tiếp theo',
        unlockYet: 'SOLFI đã mở khóa',
        claimable: 'SOLFI có thể nhận',
        get: 'Nhận',
        nodataText: 'Chưa có dữ liệu đăng ký'
    },
    user: {
        desc: 'Chia sẻ STAKOS và giúp đỡ những người cần giúp đỡ, đồng thời kiếm được lợi nhuận quan trọng trong lĩnh vực blockchain.',
        joinTime: 'Thời gian đăng ký',
        memberTeam: 'Thành viên đội',
        memberDirect: 'Thành viên giới thiệu trực tiếp',
        teamLp: 'Staking của đội',
        myLink: 'Liên kết mời của tôi',
        myTeam: 'Đội của tôi',
        personage: 'Staking cá nhân',
        cur: 'Hiện tại',
        lookDown: 'Xem cấp dưới',
        copy: 'Sao chép',
        info: 'Thông tin cá nhân'
    }
};

export default vi;
