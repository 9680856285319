const la = {
    alert:{
        reLogin: 'ທີ່ຢູ່ມີການປ່ຽນແປງ, ກະລຸນາລ໋ອກອິນອີກຄັ້ງ',
        copied: 'ຄັດລອກສຳເລັດ',
        connectWallet: 'ກະລຸນາເຊື່ອມຕໍ່ກະເປົາເງິນ',
        notBound: 'ທີ່ຢູ່ຂອງຜູ້ແນະນຳບໍ່ໄດ້ຜູກມັດ, ກະລຸນາຜູກມັດ',
        inputParent: 'ກະລຸນາໃສ່ທີ່ຢູ່ຂອງຜູ້ແນະນຳ',
        addressError: 'ກະລຸນາໃສ່ທີ່ຢູ່ກະເປົາເງິນທີ່ຖືກຕ້ອງ',
        parentError: 'ທີ່ຢູ່ຂອງຜູ້ແນະນຳຜິດ, ກະລຸນາໃສ່ໃໝ່',
        boundSuccess: 'ຜູກມັດສຳເລັດ',
        boundFailed: 'ການຜູກມັດລົ້ມເຫລວ, ກະລຸນາລອງອີກຄັ້ງ',
        success: 'ສຳເລັດ',
        failed: 'ລົ້ມເຫລວ',
        soon: 'ຈະເປີດໃນໄວໆນີ້...',
        insufficient: 'ຍອດເງິນບໍ່ພຽງພໍ',
        quotaInsufficient: 'ຈຳນວນບໍ່ພຽງພໍ',
        amountError: 'ຈຳນວນຜິດພາດ',
        minAmountError: 'ຈຳນວນຕ່ຳສຸດ',
        redeemed: 'ຄຳສັ່ງບໍ່ມີຢູ່ ຫຼືຖືກເຊັດຄືນແລ້ວ',
        enter: 'ຕົກລົງ',
        congratulation: 'ຍິນດີທີ່ທ່ານໄດ້ສຳເລັດ',
        walletInsufficient: 'ຍອດເງິນໃນກະເປົາບໍ່ພຽງພໍ',
        copySuccess: 'ຄັດລອກສຳເລັດ'
    },
    header: {
        link: 'ເຊື່ອມກະເປົາເງິນ',
        home: 'ໜ້າຫຼັກ',
        lp: 'ຝາກປະກັນ',
        award: 'ລາງວັນ',
        solfi: 'SOLFI',
        user: 'ຂອງຂ້ອຍ',
        ido: 'IDO',
        inc: '© 2024 Arctic Intelligence Inc. ສະຫຼຸບທຸກສິດ.',
        bind: 'ຕິດຕາມ',
        bindPla: 'ກະລຸນາໃສ່ທີ່ຢູ່ຜູ້ເຊີນ',
        bindTitle: 'ກະລຸນາຕິດຕາມທີ່ຢູ່ຜູ້ເຊີນ'
    },
    home:{
        title: 'ແພລດຟອມຝາກປະກັນແບບກະຈາຍອໍານາດ',
        desc: 'STAKOS ຖືກສ້າງຂຶ້ນຢູ່ເທິງພື້ນຖານຂອງສາມເຕັກໂນໂລຢີລະດັບໂລກ - ປ້ອງກັນຂໍ້ມູນ, AI ການຊື້ຂາຍ, ແລະເມການຝາກປະກັນ. ວຽກງານເຫຼົ່ານີ້ຖືກອອກແບບໃຫ້ສະຫນັບສະຫນຸນໂຄງການ ແລະໄດ້ຮັບການຮ່ວມມືຈາກສະຖາບັນການເງິນລະດັບໂລກ.',
        lp: 'ຝາກປະກັນ',
        advantageTitle1: "ປ້ອງກັນຂໍ້ມູນ",
        advantageDesc1: 'ປ້ອງກັນແບບລະດັບສູງ, ມີແຜນການຄວາມປອດໄພທີ່ຊັບຊ້ອນ, ແລະການຄວບຄຸມອັດຕະໂນມັດເພື່ອຄວາມປອດໄພໃນການປອງກັນຂໍ້ມູນ.',
        advantageTitle2: "AI ການຊື້ຂາຍ",
        advantageDesc2: 'AI ການຊື້ຂາຍຊ່ວຍໃນການປັບການໄດ້ເມກາຊື້ຂາຍ, ແລະການຄວບຄຸມອາລົມໃນການຕັດສິນໃຈຂອງມະນຸດເພື່ອປ້ອງກັນການສູນເສຍໃນການຊື້ຂາຍ.',
        advantageTitle3: "ເມກາຝາກປະກັນ",
        advantageDesc3: 'ຍອດການເພີ່ມມູນເຂົ້າໃນການຄວບຄຸມດ້ວຍສະຫນັບສະຫນຸນຂອງຂໍ້ສະເຫນີຂໍ້ຕົວເມກາ.',
        gain: 'ຜົນປະໂຫຍດສຳຄັນ',
        gainTitle1: 'ກຳໄລທີ່ສູງ',
        gainDesc1: 'ຜູ້ໃຊ້ສາມາດຝາກ USDT ແລະໄດ້ຮັບຜົນກຳໄລທີ່ສູງ.',
        gainTitle2: 'ລົດຄວາມສຽງ',
        gainDesc2: 'stakos ເປັນຊ່ອງທາງຄວບຄຸມໄດ້ເພື່ອຝາກປະກັນ.',
        gainTitle3: 'ການຮ່ວມມືເປີດກວ້າງ',
        gainDesc3: 'stakos ຈະເຮັດການຂະຫຍາຍແລະເພີ່ມອີກເປັນສ່ວນຫນຶ່ງຂອງການເປີດກວ້າງ.',
        friendTitle: 'ຜູ້ຮ່ວມງານ',
        friendDesc: 'ສະຖານີຝາກປະກັນຂອງ stakos ໄດ້ຮັບການກໍ່ສ້າງໂດຍ Seven Seas.'
    },
    pay:{
        desc: 'ປົກປ້ອງອະນາຄົດຂອງທ່ານດ້ວຍ Solana – ກະເປົາເງິນທີ່ສາມາດເຮັດໃຫ້ທຸລະກຳຮັບໄວສຸດ!',
        days: 'ມື້',
        hours: 'ຊົ່ວໂມງ',
        minutes: 'ນາທີ',
        seconds: 'ວິນາທີ',
        android: 'ດາວໂຫລດ Android',
        ios: 'ດາວໂຫລດ APP Store'
    },
    lp:{
        title: 'ການຝາກປະກັນ',
        title1: 'STAKOS ເຮັດໃຫ້ USDT ຝາກປະກັນໄດ້ງ່າຍຂຶ້ນ',
        stackDesc: 'SolFi ເຮັດໃຫ້ການແຊກແງຂອງ SOL ງ່າຍຂຶ້ນ.',
        stackList1: 'ການຝາກຂອງຂ້ອຍ',
        stackList2: 'ກຳໄລທີ່ສ້າງໄດ້',
        stackList3: 'ສຳເລັດການຟື້ນຟູ',
        lp: 'ຝາກປະກັນ',
        underway: 'ຝາກປະກັນຢູ່ໃນ',
        earn: 'ກຳໄລທີ່ໄດ້',
        yet: 'ຍອດທີ່ຍັງຄ້າງຢູ່',
        redeem: 'ເຊັດຄືນ',
        yetBut: 'ເຊັດຄືນ',
        accredit: 'ອະນຸມັດ',
        record: 'ບັນທຶກການຝາກ',
        tokenTitle: 'ຜົນກຳໄລຊ້ອນ',
        tokenDesc: 'ຜົນກຳໄລທີ່ຍັງຄ້າງຢູ່ຈະໄດ້ຮັບຜົນກຳໄລຊ້ອນ.',
        tokenList1: 'ຮັບຜົນກຳໄລຊ້ອນບໍ່?',
        tokenList2: 'ຜົນກຳໄລຊ້ອນທີ່ໄດ້ຮັບ',
        tokenList3: 'ຜົນກຳໄລຊ້ອນທີ່ຍັງຄ້າງຢູ່',
        yes: 'ແມ່ນແລ້ວ',
        no: 'ບໍ່ແມ່ນ',
        back: 'ກັບໄປຝາກປະກັນ',
        money: 'ຈຳນວນເງິນ',
        timeStart: 'ເວລາຝາກ',
        timeEnd: 'ເວລາໝົດອາຍຸ',
        cancel: 'ຍົກເລີກການຕໍ່ໄປອັດຕະໂນມັດ',
        more: 'ເພີ່ມເຕີມ...',
        max: 'ສູງສຸດ',
        quotaRemain: 'ຄົງເຫຼືອ',
        usable: 'ສາມາດໃຊ້ໄດ້',
        redeemed: 'ເຊັດຄືນ',
        redeeming: 'ກຳລັງເຊັດຄືນ...',
        staking: 'ຝາກປະກັນຢູ່...'
    },
    award:{
        communityDesc: 'ສະມາຊິກຊຸມຊົນທີ່ໄດ້ຮັບລາງວັນ',
        communityList1: 'ລາງວັນທີ່ໄດ້ຮັບ',
        communityList2: 'ມີລາຍງານ',
        communityList3: 'ຍັງຄ້າງຢູ່',
        communityBut: 'ເຊັດຄືນ',
        record: 'ບັນທຶກລາງວັນ',
        listType1: 'ລາງວັນສືບທອດ',
        listType2: 'ລາງວັນທີມ',
        listType3: 'ລາງວັນຄູ່ກັນ',
        listType4: 'ລາງວັນການແບ່ງປັນ',
        total: 'ລາງວັນທັງໝົດ'
    },
    top: {
        tokenDesc: 'SolFi Token(SOLFI) ຈະເປັນໂຕແທນທີ່ໃຊ້ງານໃນແພລັດຟອມທັງໝົດ, ແລະຈະມີຄ່າສູງໃນອະນາຄົດ.',
        price: 'ລາຄາ',
        allAmount: 'ຈຳນວນທັງໝົດ',
        market: 'ມູນຄ່າຕະຫຼາດ',
        my: 'SOLFI ຂອງຂ້ອຍ',
        balance: 'ຍອດເງິນກະເປົາ',
        buy: 'ຊື້',
        approve: 'ອະນຸມັດ SOL',
        approveTop: 'ອະນຸມັດ SOLFI',
        earn: 'ລາຍໄດ້ທີ່ໄດ້ຮັບ',
        getYet: 'ຮັບໄດ້',
        get: 'ຮັບ'
    },
    ido: {
        turn: 'ລຸ້ນທີ່ປະຈຸບັນ',
        all: 'ຈຳນວນ IDO ທັງໝົດ',
        price: 'ລາຄາ',
        limit: 'ຂີດຈຳກັດການຊື້',
        ps: 'ການປົດລອກ: ລອກໄວ້[]ເດືອນ, ແລ້ວແບ່ງປົດລອກໃນໄລຍະເວລາ 12 ເດືອນ',
        my: 'ຂີດຈຳກັດຂອງຂ້ອຍ',
        nobuy: 'ຍັງບໍ່ໄດ້ຊື້',
        balance: 'ຍອດເງິນ',
        custom: 'ຄາດຄະເນວ່າຈະໄດ້ຮັບ',
        buy: 'ຊື້ດຽວນີ້',
        buyYet: 'ຊື້ແລ້ວ',
        record: 'ບັນທຶກການຊື້',
        buyTrun: 'ລຸ້ນການຊື້',
        buyUsdt: 'ຊື້ດ້ວຍ SOL',
        getTop: 'ໄດ້ຮັບ SOLFI',
        unlock: 'ເວລາປົດລອກຕໍ່ໄປ',
        unlockYet: 'SOLFI ທີ່ປົດລອກແລ້ວ',
        claimable: 'ທີ່ສາມາດຮັບໄດ້',
        get: 'ຮັບ',
        nodataText: 'ບໍ່ມີບັນທຶກການຊື້'
    },
    user: {
        desc: 'ແບ່ງປັນ STAKOS, ຊ່ວຍເຫຼືອຜູ້ທີ່ຕ້ອງການການຊ່ວຍເຫຼືອ, ແລະສ້າງລາຍໄດ້ໃນວົງການ Blockchain.',
        joinTime: 'ເວລາລົງທະບຽນ',
        memberTeam: 'ສະມາຊິກທີມ',
        memberDirect: 'ສະມາຊິກທີມທີ່ແນະນຳໂດຍກົງ',
        teamLp: 'ການຈຳນວນທີມ',
        myLink: 'ລິ້ງຊວນຂອງຂ້ອຍ',
        myTeam: 'ທີມຂອງຂ້ອຍ',
        personage: 'ການລົງທຶນສ່ວນບຸກຄົນ',
        cur: 'ປະຈຸບັນ',
        lookDown: 'ເບິ່ງລະດັບລຸ່ມ',
        copy: 'ສຳເນົາ',
        info: 'ຂໍ້ມູນສ່ວນບຸກຄົນ'
    }
}

export default la;
    