const zh = {
    alert:{
        reLogin: '地址发生改变，请重新登录',
        copied: '复制成功',
        connectWallet: '请连接钱包',
        notBound: '未绑定推荐人地址，请绑定',
        inputParent: '请输入推荐人地址',
        addressError: '请输入正确的钱包地址',
        parentError: '推荐人地址错误，请重新输入',
        boundSuccess: '绑定成功',
        boundFailed: '绑定失败，请重试',
        success: '成功',
        failed: '失败',
        soon: '即将开放...',
        insufficient: '余额不足',
        quotaInsufficient: '额度不足',
        amountError: '金额错误',
        minAmountError: '最低',
        redeemed: '订单不存在或已经赎回',
        enter: '确定',
        congratulation: '恭喜您合成',
        walletInsufficient: '钱包余额不足',
        copySuccess: '复制成功'
    },
    header: {
        link: '连接钱包',
        home: '首页',
        lp: '质押',
        award: '奖励',
        solfi: 'SOLFI',
        user: '我的',
        ido: 'IDO',
        inc: '© 2024 Arctic Intelligence Inc. 保留所有权利。',
        bind: '绑定',
        bindPla: '请输入邀请人地址',
        bindTitle: '请绑定邀请人地址'
    },
    home:{
        title: '去中心化质押平台',
        desc: 'STAKOS以三项全球顶级的专项技术——数字堡垒、AI交易和再质押机制为基础，这些技术为项目提供了技术支持。因此，斯投富吸引了全球范围内的粉丝参与，并获得了权威金融机构的紧密合作。',
        lp: '质押',
        advantageTitle1: "数字堡垒",
        advantageDesc1: '高强度防护、多层次安全策略、智能防御机制，确保数据安全无虞，应对网络威胁游刃有余。',
        advantageTitle2: "AI交易",
        advantageDesc2: '通过先进的AI技术，AI交易能够自动优化交易策略，快速响应市场变化，并减少人为情绪波动对交易决策的影响，从而提高交易效率和投资收益。',
        advantageTitle3: "再质押机制",
        advantageDesc3: '提高资本效率，允许资产多次质押；增强网络安全性，支持多个协议；提供额外奖励，激励质押者承担更多风险；通过智能合约实现自动化管理。该机制利用已质押资产支持其他协议，实现资源共享和安全性提升。',
        gain: '主要利益',
        gainTitle1: '高额利润',
        gainDesc1: '用户可以通过质押 USDT 资产来赚取高额利润，实现资产增值',
        gainTitle2: '多样化投资',
        gainDesc2: 'stakos让用户可以一站式参与质押和流动性挖矿，实现资金的多样化投资，降低风险。',
        gainTitle3: '开放式生态系统',
        gainDesc3: '作为开放式生态系统的一部分，stakos将不断扩展并加入更多优秀的 DeFi 协议，为用户提供更广泛的金融服务和收益机会。',
        friendTitle: '合作伙伴',
        friendDesc: 'stakos 的质押矿池策略由 Seven Seas 提供，他们是 DeFi 领域顶级的质押矿池建设者之一。通过与 Seven Seas 的合作，stakos 能够为用户提供安全可靠的质押服务，并持续优化质押策略以实现更高的收益。'
    },
    pay:{
        desc: '在 Solana 上保障您的未来 – 您可以信赖的钱包，可实现闪电般的快速交易！',
        days: '天',
        hours: '时',
        minutes: '分',
        seconds: '秒',
        android: 'Android下载',
        ios: 'APP Store下载'
    },
    lp:{
        title: 'Stacking',
        title1: 'STAKOS将USDT质押变得更简单，为投资者和社区成员提供持续的价值和可观的回报。',
        stackDesc: 'SolFi将SOL质押变得更简单，为投资者和社区成员提供持续的价值和可观的回报。',
        stackList1: '我的质押',
        stackList2: '已赚取',
        stackList3: '已复利获得',
        lp: '质押',
        underway: '质押中',
        earn: '已赚取收益',
        yet: '可提取收益',
        redeem: '赎回',
        yetBut: '提取',
        accredit: '授权',
        record: '质押记录',
        tokenTitle: '复利收益',
        tokenDesc: '不提取质押产生的收益将产生复利收益，按1SOLFI = 1SOL配发SOLFI代币。SolFi Token(SOLFI)将作为整个平台的功能代币，可参与体验集成AI服务和社区治理，在未来将极具价值。',
        tokenList1: '是否可享受复利',
        tokenList2: '已获得复利收益',
        tokenList3: '可提取复利收益',
        yes: '是',
        no: '否',
        back: '返回质押',
        money: '金额',
        timeStart: '质押时间',
        timeEnd: '到期时间',
        cancel: '取消自动续期',
        more: '更多...',
        max: '最大',
        quotaRemain: '余额',
        usable: '可质押',
        redeemed: '已赎回',
        redeeming: '赎回中...',
        staking: '质押中...'
    },
    award:{
        communityDesc: '为社区发展做出贡献的社区成员，将获得社区专项奖励。',
        communityList1: '已获取奖励',
        communityList2: '已提取',
        communityList3: '可提取',
        communityBut: '提取',
        record: '奖励记录',
        listType1: '直推奖励',
        listType2: '团队奖励',
        listType3: '平级奖励',
        listType4: '分红奖励',
        total: '总奖励'
    },
    top:{
        tokenDesc: 'SolFi Token(SOLFI)将作为整个平台的功能代币，可参与体验集成AI服务和社区治理，在未来将极具价值。',
        price: '价格',
        allAmount: '总供应量',
        market: '市值',
        my: '我的SOLFI',
        balance: '钱包余额',
        buy: 'Swap',
        approve: '批准SOL',
        approveTop: '批准SOLFI',
        earn: '已赚取',
        getYet: '可领取',
        get: '领取'
    },
    ido:{
        turn: '当前轮次',
        all: 'IDO总量',
        price: '价格',
        limit: '认购额度',
        ps: '解锁方式：锁定[]个月后，分12个月线性解锁',
        my: '我的额度',
        nobuy: '未认购',
        balance: '余额',
        custom: '预计获得',
        buy: '立即认购',
        buyYet: '已认购',
        record: '认购记录',
        buyTrun: '认购轮次',
        buyUsdt: '认购SOL',
        getTop: '获得SOLFI',
        unlock: '下一次解锁时间',
        unlockYet: '已解锁SOLFI',
        claimable: '可领取SOLFI',
        get: '领取',
        nodataText: '暂无认购记录'
    },
    user:{
        desc: '分享STAKOS，帮助更多需要帮助的人们，在区块链领域赚取可观收益。',
        joinTime: '注册时间',
        memberTeam: '团队成员',
        memberDirect: '直推成员',
        teamLp: '团队质押',
        myLink: '我的邀请链接',
        myTeam: '我的团队',
        personage: '个人质押',
        cur: '当前',
        lookDown: '查看下级',
        copy: '复制',
        info: '个人信息'
    }
}

export default zh;
