const en = {
    alert: {
        reLogin: 'Address changed, please log in again',
        copied: 'Copied successfully',
        connectWallet: 'Please connect your wallet',
        notBound: 'Referral address not bound, please bind',
        inputParent: 'Please enter referral address',
        addressError: 'Please enter a valid wallet address',
        parentError: 'Referral address is incorrect, please re-enter',
        boundSuccess: 'Binding successful',
        boundFailed: 'Binding failed, please retry',
        success: 'Success',
        failed: 'Failed',
        soon: 'Coming soon...',
        insufficient: 'Insufficient balance',
        quotaInsufficient: 'Insufficient quota',
        amountError: 'Amount error',
        minAmountError: 'Minimum',
        redeemed: 'Order does not exist or has been redeemed',
        enter: 'Confirm',
        congratulation: 'Congratulations on merging',
        walletInsufficient: 'Insufficient wallet balance',
        copySuccess: 'Copied successfully'
    },
    header: {
        link: 'Connect',
        home: 'Home',
        lp: 'Staking',
        award: 'Rewards',
        solfi: 'SOLFI',
        user: 'My',
        ido: 'IDO',
        inc: '© 2024 Arctic Intelligence Inc. All rights reserved.',
        bind: 'Bind',
        bindPla: 'Please enter inviter address',
        bindTitle: 'Please bind the inviter address'
    },
    home: {
        title: 'Decentralized Staking Platform',
        desc: 'STAKOS is based on three cutting-edge global technologies—Digital Fortress, AI Trading, and Re-staking Mechanism, which provide technical support to the project. As a result, STAKOS has attracted a global fanbase and gained close cooperation with authoritative financial institutions.',
        lp: 'Staking',
        advantageTitle1: "Digital Fortress",
        advantageDesc1: 'High-intensity protection, multi-layered security strategy, and intelligent defense mechanism ensure data security and effectively respond to cyber threats.',
        advantageTitle2: "AI Trading",
        advantageDesc2: 'With advanced AI technology, AI trading can automatically optimize trading strategies, respond quickly to market changes, and reduce the impact of emotional fluctuations on trading decisions, thus improving trading efficiency and investment returns.',
        advantageTitle3: "Re-staking Mechanism",
        advantageDesc3: 'Improves capital efficiency by allowing assets to be staked multiple times; enhances network security by supporting multiple protocols; provides additional rewards to incentivize stakers to take on more risk; and automates management through smart contracts. This mechanism allows staked assets to support other protocols, achieving resource sharing and enhanced security.',
        gain: 'Key Benefits',
        gainTitle1: 'High Profits',
        gainDesc1: 'Users can earn high profits by staking USDT assets, achieving asset growth',
        gainTitle2: 'Diversified Investment',
        gainDesc2: 'STAKOS allows users to participate in staking and liquidity mining in one place, achieving diversified investment and reducing risk.',
        gainTitle3: 'Open Ecosystem',
        gainDesc3: 'As part of an open ecosystem, STAKOS will continue to expand and integrate more excellent DeFi protocols, providing users with a broader range of financial services and earning opportunities.',
        friendTitle: 'Partners',
        friendDesc: 'STAKOS’s staking pool strategy is provided by Seven Seas, one of the top staking pool builders in the DeFi field. Through cooperation with Seven Seas, STAKOS can provide users with secure and reliable staking services and continuously optimize staking strategies to achieve higher returns.'
    },
    pay: {
        desc: 'Secure your future on Solana – A wallet you can trust with lightning-fast transactions!',
        days: 'Days',
        hours: 'Hours',
        minutes: 'Minutes',
        seconds: 'Seconds',
        android: 'Download for Android',
        ios: 'Download on App Store'
    },
    lp: {
        title: 'Staking',
        title1: 'STAKOS makes USDT staking simpler, providing continuous value and substantial returns for investors and community members.',
        stackDesc: 'SolFi makes SOL staking simpler, providing continuous value and substantial returns for investors and community members.',
        stackList1: 'My Staking',
        stackList2: 'Earned',
        stackList3: 'Earned Compound Interest',
        lp: 'Staking',
        underway: 'Staking in Progress',
        earn: 'Earned Income',
        yet: 'Available Income',
        redeem: 'Redeem',
        yetBut: 'Withdraw',
        accredit: 'Authorize',
        record: 'Staking Records',
        tokenTitle: 'Compound Interest',
        tokenDesc: 'Not withdrawing staking earnings will generate compound interest, distributed as 1 SOLFI = 1 SOL. SolFi Token (SOLFI) will serve as the utility token of the entire platform, enabling participation in integrated AI services and community governance, and will be highly valuable in the future.',
        tokenList1: 'Eligible for Compound Interest',
        tokenList2: 'Earned Compound Interest',
        tokenList3: 'Available Compound Interest',
        yes: 'Yes',
        no: 'No',
        back: 'Back to Staking',
        money: 'Amount',
        timeStart: 'Staking Start Time',
        timeEnd: 'End Time',
        cancel: 'Cancel Auto-Renewal',
        more: 'More...',
        max: 'Maximum',
        quotaRemain: 'Remaining Balance',
        usable: 'Available for Staking',
        redeemed: 'Redeemed',
        redeeming: 'Redeeming...',
        staking: 'Staking in Progress...'
    },
    award: {
        communityDesc: 'Community members who contribute to the development of the community will receive special community rewards.',
        communityList1: 'Rewards Earned',
        communityList2: 'Withdrawn',
        communityList3: 'Available to Withdraw',
        communityBut: 'Withdraw',
        record: 'Reward Records',
        listType1: 'Direct Referral Rewards',
        listType2: 'Team Rewards',
        listType3: 'Equal Level Rewards',
        listType4: 'Bonus Rewards',
        total: 'Total Rewards'
    },
    top: {
        tokenDesc: 'SolFi Token (SOLFI) will serve as the utility token of the entire platform, enabling participation in integrated AI services and community governance, and will be highly valuable in the future.',
        price: 'Price',
        allAmount: 'Total Supply',
        market: 'Market Cap',
        my: 'My SOLFI',
        balance: 'Wallet Balance',
        buy: 'Swap',
        approve: 'Approve SOL',
        approveTop: 'Approve SOLFI',
        earn: 'Earned',
        getYet: 'Available to Claim',
        get: 'Claim'
    },
    ido: {
        turn: 'Current Round',
        all: 'Total IDO Amount',
        price: 'Price',
        limit: 'Subscription Limit',
        ps: 'Unlocking method: locked for [] months, then unlocked linearly over 12 months',
        my: 'My Subscription Limit',
        nobuy: 'Not Subscribed',
        balance: 'Balance',
        custom: 'Estimated to Receive',
        buy: 'Subscribe Now',
        buyYet: 'Subscribed',
        record: 'Subscription Records',
        buyTrun: 'Subscription Round',
        buyUsdt: 'Subscribe with SOL',
        getTop: 'Receive SOLFI',
        unlock: 'Next Unlock Time',
        unlockYet: 'Unlocked SOLFI',
        claimable: 'Claimable SOLFI',
        get: 'Claim',
        nodataText: 'No subscription records yet'
    },
    user: {
        desc: 'Share STAKOS and help more people in need while earning substantial returns in the blockchain field.',
        joinTime: 'Registration Time',
        memberTeam: 'Team Members',
        memberDirect: 'Direct Referral Members',
        teamLp: 'Team Staking',
        myLink: 'My Referral Link',
        myTeam: 'My Team',
        personage: 'Personal Staking',
        cur: 'Current',
        lookDown: 'View Subordinates',
        copy: 'Copy',
        info: 'Personal Information'
    }
}

export default en;
