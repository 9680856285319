const ru = {
    alert: {
        reLogin: 'Адрес изменен, пожалуйста, войдите снова',
        copied: 'Скопировано успешно',
        connectWallet: 'Пожалуйста, подключите кошелек',
        notBound: 'Реферальный адрес не привязан, пожалуйста, привяжите его',
        inputParent: 'Введите адрес реферера',
        addressError: 'Введите правильный адрес кошелька',
        parentError: 'Неверный адрес реферера, введите снова',
        boundSuccess: 'Привязка успешна',
        boundFailed: 'Не удалось привязать, попробуйте снова',
        success: 'Успех',
        failed: 'Не удалось',
        soon: 'Скоро откроется...',
        insufficient: 'Недостаточный баланс',
        quotaInsufficient: 'Недостаточно квоты',
        amountError: 'Ошибка в сумме',
        minAmountError: 'Минимальная сумма',
        redeemed: 'Заказ не существует или уже был погашен',
        enter: 'Подтвердить',
        congratulation: 'Поздравляем с успешным объединением',
        walletInsufficient: 'Недостаточно средств в кошельке',
        copySuccess: 'Скопировано успешно'
    },
    header: {
        link: 'Подключить кошелек',
        home: 'Главная',
        lp: 'Стейкинг',
        award: 'Награды',
        solfi: 'SOLFI',
        user: 'Мой профиль',
        ido: 'IDO',
        inc: '© 2024 Arctic Intelligence Inc. Все права защищены.',
        bind: 'Привязать',
        bindPla: 'Введите адрес пригласившего',
        bindTitle: 'Привяжите адрес пригласившего'
    },
    home: {
        title: 'Децентрализованная платформа стейкинга',
        desc: 'STAKOS основан на трех ведущих мировых технологиях — цифровой крепости, AI-трейдинге и механизме повторного стейкинга. Эти технологии предоставляют проекту мощную техническую поддержку, что привлекает поклонников со всего мира и поддерживает тесное сотрудничество с авторитетными финансовыми институтами.',
        lp: 'Стейкинг',
        advantageTitle1: 'Цифровая крепость',
        advantageDesc1: 'Многоуровневая защита, продвинутые механизмы безопасности и интеллектуальная защита данных для надежного противостояния киберугрозам.',
        advantageTitle2: 'AI-трейдинг',
        advantageDesc2: 'Используя передовые технологии искусственного интеллекта, AI-трейдинг может автоматически оптимизировать торговые стратегии, быстро реагировать на рыночные изменения и минимизировать влияние человеческих эмоций на торговые решения, что повышает эффективность и доходность торговли.',
        advantageTitle3: 'Механизм повторного стейкинга',
        advantageDesc3: 'Повышает эффективность капитала, позволяя активам участвовать в нескольких стейкингах; улучшает безопасность сети и поддерживает несколько протоколов; предлагает дополнительные вознаграждения и мотивирует участников брать на себя больше рисков. Этот механизм использует уже стейканные активы для поддержки других протоколов, обеспечивая распределение ресурсов и повышение безопасности.',
        gain: 'Основные преимущества',
        gainTitle1: 'Высокая прибыль',
        gainDesc1: 'Пользователи могут стейкать USDT и получать высокие прибыли, увеличивая свои активы.',
        gainTitle2: 'Диверсификация инвестиций',
        gainDesc2: 'STAKOS позволяет пользователям участвовать в стейкинге и ликвидных пулах, диверсифицируя инвестиции и снижая риски.',
        gainTitle3: 'Открытая экосистема',
        gainDesc3: 'Будучи частью открытой экосистемы, STAKOS будет постоянно расширяться и подключать лучшие DeFi-протоколы, предоставляя пользователям больше финансовых услуг и возможностей для получения дохода.',
        friendTitle: 'Партнеры',
        friendDesc: 'Стратегию стейкинг-пулов STAKOS поддерживает Seven Seas, один из ведущих строителей стейкинг-пулов в области DeFi. Сотрудничество с Seven Seas позволяет STAKOS предоставлять безопасные и надежные услуги стейкинга и постоянно оптимизировать стратегии для повышения доходности.'
    },
    pay: {
        desc: 'Обеспечьте свое будущее с помощью Solana – кошелек, которому можно доверять, с молниеносными транзакциями!',
        days: 'дней',
        hours: 'часов',
        minutes: 'минут',
        seconds: 'секунд',
        android: 'Скачать для Android',
        ios: 'Скачать в App Store'
    },
    lp: {
        title: 'Стейкинг',
        title1: 'STAKOS упрощает стейкинг USDT, предоставляя инвесторам и участникам сообщества постоянную ценность и значительные доходы.',
        stackDesc: 'SolFi упрощает стейкинг SOL, предоставляя инвесторам и участникам сообщества постоянную ценность и значительные доходы.',
        stackList1: 'Мой стейкинг',
        stackList2: 'Заработано',
        stackList3: 'Получено с реинвестированием',
        lp: 'Стейкинг',
        underway: 'Стейкинг в процессе',
        earn: 'Заработанные доходы',
        yet: 'Доступные для изъятия доходы',
        redeem: 'Погасить',
        yetBut: 'Извлечь',
        accredit: 'Авторизовать',
        record: 'История стейкинга',
        tokenTitle: 'Доход от реинвестирования',
        tokenDesc: 'Доходы, оставшиеся в стейкинге, будут автоматически реинвестированы. SOLFI токены распределяются по схеме 1 SOLFI = 1 SOL. Токен SolFi (SOLFI) станет функциональной валютой всей платформы, позволяя участвовать в AI-услугах и управлении сообществом, а в будущем обретет значительную ценность.',
        tokenList1: 'Право на реинвестирование',
        tokenList2: 'Полученные доходы от реинвестирования',
        tokenList3: 'Доступные для изъятия доходы от реинвестирования',
        yes: 'Да',
        no: 'Нет',
        back: 'Вернуться к стейкингу',
        money: 'Сумма',
        timeStart: 'Время начала стейкинга',
        timeEnd: 'Время окончания стейкинга',
        cancel: 'Отменить автопродление',
        more: 'Подробнее...',
        max: 'Максимум',
        quotaRemain: 'Остаток',
        usable: 'Доступно для стейкинга',
        redeemed: 'Погашено',
        redeeming: 'Погашение в процессе...',
        staking: 'Стейкинг в процессе...'
    },
    award: {
        communityDesc: 'Участники сообщества, сделавшие вклад в его развитие, получают специальные награды.',
        communityList1: 'Полученные награды',
        communityList2: 'Извлечено',
        communityList3: 'Доступные для извлечения',
        communityBut: 'Извлечь',
        record: 'История наград',
        listType1: 'Реферальная награда',
        listType2: 'Командная награда',
        listType3: 'Награда за равенство',
        listType4: 'Дивиденды',
        total: 'Общая награда'
    },
    top: {
        tokenDesc: 'Токен SolFi (SOLFI) станет функциональной валютой всей платформы, позволяя участвовать в AI-услугах и управлении сообществом, а в будущем обретет значительную ценность.',
        price: 'Цена',
        allAmount: 'Общее предложение',
        market: 'Рыночная стоимость',
        my: 'Мои SOLFI',
        balance: 'Баланс кошелька',
        buy: 'Обменять',
        approve: 'Одобрить SOL',
        approveTop: 'Одобрить SOLFI',
        earn: 'Заработано',
        getYet: 'Доступно для получения',
        get: 'Получить'
    },
    ido: {
        turn: 'Текущий раунд',
        all: 'Общее количество IDO',
        price: 'Цена',
        limit: 'Лимит подписки',
        ps: 'Метод разблокировки: [] месяцев блокировки, затем линейная разблокировка в течение 12 месяцев',
        my: 'Мой лимит',
        nobuy: 'Не подписано',
        balance: 'Баланс',
        custom: 'Ожидаемое получение',
        buy: 'Подписаться сейчас',
        buyYet: 'Уже подписано',
        record: 'История подписок',
        buyTrun: 'Раунд подписки',
        buyUsdt: 'Подписка на SOL',
        getTop: 'Получить SOLFI',
        unlock: 'Следующее время разблокировки',
        unlockYet: 'Разблокировано SOLFI',
        claimable: 'Доступное для получения SOLFI',
        get: 'Получить',
        nodataText: 'Нет данных о подписках'
    },
    user: {
        desc: 'Поделитесь STAKOS и помогите тем, кто нуждается в помощи, зарабатывая значительные доходы в области блокчейна.',
        joinTime: 'Дата регистрации',
        memberTeam: 'Члены команды',
        memberDirect: 'Прямые рефералы',
        teamLp: 'Командный стейкинг',
        myLink: 'Моя реферальная ссылка',
        myTeam: 'Моя команда',
        personage: 'Личный стейкинг',
        cur: 'Текущий',
        lookDown: 'Посмотреть нижестоящих',
        copy: 'Скопировать',
        info: 'Личная информация'
    }
};

export default ru;
