<template>
    <div id="app">
        <router-view />
       <div id="nav" v-if="show">
           <router-link to="/">
               <img src="./static/images/header/home.png" v-if="link" />
               <img src="./static/images/header/home-s.png" v-else />
               <span :style="!link?'color: #FFFFFF':''">{{$t('header.home')}}</span>
           </router-link>
           <router-link class="subscription" to="/lp">
               <img src="./static/images/header/lp.png" v-if="link!=='lp'" />
               <img src="./static/images/header/lp-s.png" v-else />
               <span :style="link==='lp'?'color: #FFFFFF':''">{{$t('header.lp')}}</span>
           </router-link>
           <router-link to="/award">
               <img src="./static/images/header/award.png" v-if="link!=='award'" />
               <img src="./static/images/header/award-s.png" v-else />
               <span :style="link==='award'?'color: #FFFFFF':''">{{$t('header.award')}}</span>
           </router-link>
           <router-link to="/user">
               <img src="./static/images/header/user.png" v-if="link!=='user'" />
               <img src="./static/images/header/user-s.png" v-else/>
               <span :style="link==='user'?'color: #FFFFFF':''">{{$t('header.user')}}</span>
           </router-link>
       </div>
    </div>
</template>
<script>
export default ({
    data() {
        return{
            link: '',
            show: true
        }
    },
    watch:{
        $route(newval,oldval){
            this.link = newval.path.slice(1,newval.path.length);
        }
    },
    methods:{
        showTabBar(bool){
            this.show = bool;
        },
        handleNewEvent(event) {
        },
    }
})
</script>

<style>
    body {
        margin: 0;
        font-size: 14px;
        color: white;
    }
    #app {
        width: 100%;
        /* max-width: 768px; */
        min-height: 100vh;
        overflow: hidden;
        margin: 0 auto;
        background: #F5F4F6;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        position: relative;
        background-color: #1C1D21;
    }
    #nav {
        position: fixed;
        bottom: 0;
        z-index: 1001;
        width: 100%;
        /* max-width: 768px; */
        height: 70px;
        display: none;
        justify-content: space-around;
        background-color: #22252A;
        /* border-top: 1px solid #e0e0e0; */
    }
    #nav a{
        width: 25%;
        color: #999;
        text-decoration: none;
        font-size: 12px;
        display: flex;
        flex-direction: column;
        font-weight: 500;
        align-items: center;
    }
    #nav a img{
        width: 24px;
        margin: 10px 0 8px;
    }
    button:focus{
        outline: none;
    }
    .module-wid{
        width: calc(100% - 52px);
        margin: 0 auto;
        background-color: white;
        padding: 16px;
        border-radius: 4px;
    }
    button{
        border: none;
        cursor: pointer;
        background: linear-gradient( 90deg, #D99F2D 0%, #EC5C70 33%, #E15DDF 67%, #7377F0 100%);
        border-radius: 16px 16px 16px 16px;
    }
    .more{
        text-align: center;
        margin: 20px auto;
        font-size: 14px;
        color:#777777;
        cursor: pointer;
    }
    /* .subscription{
        margin-top: -24px;
    }
    .subscription img{
        width: 47px !important;
        height: 47px !important;
    } */
    .no-data{
        display: flex;
        justify-content: center;
        margin-top: 50px;
    }
    .no-data img{
        width: 100px;
        height: 100px;
    }
    .top-gap{
        height: 80px;
    }
    .max-width{
        width: calc(100% - 30px);
        max-width: 1200px;
        margin: 0 auto;
    }
    @media screen and (max-width:768px) {
        .top-gap{
            height: 70px;
        }
        #nav{
            display: flex;
        }
    }
</style>
