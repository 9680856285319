const zhTw = {
    alert: {
        reLogin: '地址發生變更，請重新登錄',
        copied: '複製成功',
        connectWallet: '請連接錢包',
        notBound: '未綁定推薦人地址，請綁定',
        inputParent: '請輸入推薦人地址',
        addressError: '請輸入正確的錢包地址',
        parentError: '推薦人地址錯誤，請重新輸入',
        boundSuccess: '綁定成功',
        boundFailed: '綁定失敗，請重試',
        success: '成功',
        failed: '失敗',
        soon: '即將開放...',
        insufficient: '餘額不足',
        quotaInsufficient: '額度不足',
        amountError: '金額錯誤',
        minAmountError: '最低',
        redeemed: '訂單不存在或已經贖回',
        enter: '確定',
        congratulation: '恭喜您合成',
        walletInsufficient: '錢包餘額不足',
        copySuccess: '複製成功'
    },
    header: {
        link: '連接錢包',
        home: '首頁',
        lp: '質押',
        award: '獎勵',
        solfi: 'SOLFI',
        user: '我的',
        ido: 'IDO',
        inc: '© 2024 Arctic Intelligence Inc. 保留所有權利。',
        bind: '綁定',
        bindPla: '請輸入邀請人地址',
        bindTitle: '請綁定邀請人地址'
    },
    home: {
        title: '去中心化質押平台',
        desc: 'STAKOS以三項全球頂級的專項技術——數字堡壘、AI交易和再質押機制為基礎，這些技術為項目提供了技術支持。因此，STAKOS吸引了全球範圍內的粉絲參與，並獲得了權威金融機構的緊密合作。',
        lp: '質押',
        advantageTitle1: "數字堡壘",
        advantageDesc1: '高強度防護、多層次安全策略、智能防禦機制，確保數據安全無虞，應對網絡威脅游刃有餘。',
        advantageTitle2: "AI交易",
        advantageDesc2: '通過先進的AI技術，AI交易能夠自動優化交易策略，快速響應市場變化，並減少人為情緒波動對交易決策的影響，從而提高交易效率和投資收益。',
        advantageTitle3: "再質押機制",
        advantageDesc3: '提高資本效率，允許資產多次質押；增強網絡安全性，支持多個協議；提供額外獎勵，激勵質押者承擔更多風險；通過智能合約實現自動化管理。該機制利用已質押資產支持其他協議，實現資源共享和安全性提升。',
        gain: '主要利益',
        gainTitle1: '高額利潤',
        gainDesc1: '用戶可以通過質押 USDT 資產來賺取高額利潤，實現資產增值',
        gainTitle2: '多樣化投資',
        gainDesc2: 'STAKOS讓用戶可以一站式參與質押和流動性挖礦，實現資金的多樣化投資，降低風險。',
        gainTitle3: '開放式生態系統',
        gainDesc3: '作為開放式生態系統的一部分，STAKOS將不斷擴展並加入更多優秀的 DeFi 協議，為用戶提供更廣泛的金融服務和收益機會。',
        friendTitle: '合作夥伴',
        friendDesc: 'STAKOS 的質押礦池策略由 Seven Seas 提供，他們是 DeFi 領域頂級的質押礦池建設者之一。通過與 Seven Seas 的合作，STAKOS 能夠為用戶提供安全可靠的質押服務，並持續優化質押策略以實現更高的收益。'
    },
    pay: {
        desc: '在 Solana 上保障您的未來 – 您可以信賴的錢包，可實現閃電般的快速交易！',
        days: '天',
        hours: '時',
        minutes: '分',
        seconds: '秒',
        android: 'Android 下載',
        ios: 'APP Store 下載'
    },
    lp: {
        title: '質押',
        title1: 'STAKOS將USDT質押變得更簡單，為投資者和社區成員提供持續的價值和可觀的回報。',
        stackDesc: 'SolFi將SOL質押變得更簡單，為投資者和社區成員提供持續的價值和可觀的回報。',
        stackList1: '我的質押',
        stackList2: '已賺取',
        stackList3: '已複利獲得',
        lp: '質押',
        underway: '質押中',
        earn: '已賺取收益',
        yet: '可提取收益',
        redeem: '贖回',
        yetBut: '提取',
        accredit: '授權',
        record: '質押記錄',
        tokenTitle: '複利收益',
        tokenDesc: '不提取質押產生的收益將產生複利收益，按1SOLFI = 1SOL配發SOLFI代幣。SolFi Token(SOLFI)將作為整個平台的功能代幣，可參與體驗集成AI服務和社區治理，在未來將極具價值。',
        tokenList1: '是否可享受複利',
        tokenList2: '已獲得複利收益',
        tokenList3: '可提取複利收益',
        yes: '是',
        no: '否',
        back: '返回質押',
        money: '金額',
        timeStart: '質押時間',
        timeEnd: '到期時間',
        cancel: '取消自動續期',
        more: '更多...',
        max: '最大',
        quotaRemain: '餘額',
        usable: '可質押',
        redeemed: '已贖回',
        redeeming: '贖回中...',
        staking: '質押中...'
    },
    award: {
        communityDesc: '為社區發展做出貢獻的社區成員，將獲得社區專項獎勵。',
        communityList1: '已獲取獎勵',
        communityList2: '已提取',
        communityList3: '可提取',
        communityBut: '提取',
        record: '獎勵記錄',
        listType1: '直推獎勵',
        listType2: '團隊獎勵',
        listType3: '平級獎勵',
        listType4: '分紅獎勵',
        total: '總獎勵'
    },
    top: {
        tokenDesc: 'SolFi Token(SOLFI)將作為整個平台的功能代幣，可參與體驗集成AI服務和社區治理，在未來將極具價值。',
        price: '價格',
        allAmount: '總供應量',
        market: '市值',
        my: '我的SOLFI',
        balance: '錢包餘額',
        buy: 'Swap',
        approve: '批准SOL',
        approveTop: '批准SOLFI',
        earn: '已賺取',
        getYet: '可領取',
        get: '領取'
    },
    ido: {
        turn: '當前輪次',
        all: 'IDO總量',
        price: '價格',
        limit: '認購額度',
        ps: '解鎖方式：鎖定[]個月後，分12個月線性解鎖',
        my: '我的額度',
        nobuy: '未認購',
        balance: '餘額',
        custom: '預計獲得',
        buy: '立即認購',
        buyYet: '已認購',
        record: '認購記錄',
        buyTrun: '認購輪次',
        buyUsdt: '認購SOL',
        getTop: '獲得SOLFI',
        unlock: '下一次解鎖時間',
        unlockYet: '已解鎖SOLFI',
        claimable: '可領取SOLFI',
        get: '領取',
        nodataText: '暫無認購記錄'
    },
    user: {
        desc: '分享STAKOS，幫助更多需要幫助的人，同時在區塊鏈領域賺取豐厚回報。',
        joinTime: '註冊時間',
        memberTeam: '團隊成員',
        memberDirect: '直推成員',
        teamLp: '團隊質押',
        myLink: '我的邀請連結',
        myTeam: '我的團隊',
        personage: '個人質押',
        cur: '當前',
        lookDown: '查看下級',
        copy: '複製',
        info: '個人資訊'
    }
}

export default zhTw;
