const ko = {
    alert: {
        reLogin: '주소가 변경되었습니다. 다시 로그인 해주세요',
        copied: '복사 성공',
        connectWallet: '지갑을 연결해주세요',
        notBound: '추천인 주소가 연결되지 않았습니다. 연결해주세요',
        inputParent: '추천인 주소를 입력해주세요',
        addressError: '올바른 지갑 주소를 입력해주세요',
        parentError: '추천인 주소가 잘못되었습니다. 다시 입력해주세요',
        boundSuccess: '연결 성공',
        boundFailed: '연결 실패. 다시 시도해주세요',
        success: '성공',
        failed: '실패',
        soon: '곧 오픈 예정...',
        insufficient: '잔액 부족',
        quotaInsufficient: '한도 부족',
        amountError: '금액 오류',
        minAmountError: '최소',
        redeemed: '주문이 없거나 이미 상환되었습니다',
        enter: '확인',
        congratulation: '축하합니다, 합성에 성공했습니다',
        walletInsufficient: '지갑 잔액 부족',
        copySuccess: '복사 성공'
    },
    header: {
        link: '지갑 연결',
        home: '홈',
        lp: '스테이킹',
        award: '보상',
        solfi: 'SOLFI',
        user: '내 정보',
        ido: 'IDO',
        inc: '© 2024 Arctic Intelligence Inc. 모든 권리 보유.',
        bind: '연결',
        bindPla: '초대자 주소를 입력해주세요',
        bindTitle: '초대자 주소를 연결해주세요'
    },
    home: {
        title: '탈중앙화 스테이킹 플랫폼',
        desc: 'STAKOS는 디지털 요새, AI 트레이딩, 그리고 재스테이킹 메커니즘이라는 세 가지 세계 최고 기술을 기반으로 합니다. 이 기술들은 프로젝트에 기술적 지원을 제공하며, STAKOS는 전 세계 팬들을 끌어들이고 있으며, 권위 있는 금융 기관과 긴밀히 협력하고 있습니다.',
        lp: '스테이킹',
        advantageTitle1: "디지털 요새",
        advantageDesc1: '고강도의 보호, 다층 보안 전략, 지능형 방어 메커니즘으로 데이터 보안을 유지하고 네트워크 위협에 대응합니다.',
        advantageTitle2: "AI 트레이딩",
        advantageDesc2: '고급 AI 기술을 통해, AI 트레이딩은 거래 전략을 자동으로 최적화하고 시장 변화에 빠르게 반응합니다. 또한, 감정적 요인의 영향을 줄여 거래 효율성과 투자 수익을 향상시킵니다.',
        advantageTitle3: "재스테이킹 메커니즘",
        advantageDesc3: '자본 효율성을 높이고 자산을 여러 번 스테이킹할 수 있게 합니다. 네트워크 보안을 강화하고 여러 프로토콜을 지원합니다. 스테이킹하는 사람들에게 추가 보상을 제공하여 더 큰 리스크를 감수하게 유도하며, 스마트 계약을 통해 자동 관리가 가능합니다. 이미 스테이킹된 자산을 다른 프로토콜에서 활용하여 리소스 공유와 보안성을 강화합니다.',
        gain: '주요 이익',
        gainTitle1: '높은 수익',
        gainDesc1: '사용자는 USDT 자산을 스테이킹하여 높은 수익을 얻고 자산 가치를 증가시킬 수 있습니다.',
        gainTitle2: '다양한 투자 옵션',
        gainDesc2: 'STAKOS는 사용자가 한 번에 스테이킹 및 유동성 채굴에 참여하여 자금을 다양하게 투자하고 리스크를 줄일 수 있게 합니다.',
        gainTitle3: '오픈 생태계',
        gainDesc3: 'STAKOS는 오픈 생태계의 일부로서 지속적으로 확장되어 더 많은 우수한 DeFi 프로토콜을 연결하고 사용자에게 다양한 금융 서비스와 수익 기회를 제공합니다.',
        friendTitle: '파트너',
        friendDesc: 'STAKOS의 스테이킹 풀 전략은 DeFi 분야에서 최고의 스테이킹 풀 구축자 중 하나인 Seven Seas에 의해 제공됩니다. Seven Seas와의 협력을 통해 STAKOS는 사용자에게 안전하고 신뢰할 수 있는 스테이킹 서비스를 제공하고 스테이킹 전략을 지속적으로 최적화하여 더 높은 수익을 달성할 수 있습니다.'
    },
    pay: {
        desc: 'Solana에서 여러분의 미래를 보호하세요 – 믿을 수 있는 지갑과 초고속 거래가 가능합니다!',
        days: '일',
        hours: '시간',
        minutes: '분',
        seconds: '초',
        android: 'Android 다운로드',
        ios: 'APP Store 다운로드'
    },
    lp: {
        title: '스테이킹',
        title1: 'STAKOS는 USDT 스테이킹을 더욱 쉽게 만들어 투자자와 커뮤니티 구성원에게 지속적인 가치와 높은 수익을 제공합니다.',
        stackDesc: 'SolFi는 SOL 스테이킹을 더욱 쉽게 만들어 투자자와 커뮤니티 구성원에게 지속적인 가치와 높은 수익을 제공합니다.',
        stackList1: '내 스테이킹',
        stackList2: '획득한 수익',
        stackList3: '복리로 얻은 수익',
        lp: '스테이킹',
        underway: '스테이킹 진행 중',
        earn: '획득한 수익',
        yet: '출금 가능한 수익',
        redeem: '상환',
        yetBut: '출금',
        accredit: '승인',
        record: '스테이킹 기록',
        tokenTitle: '복리 수익',
        tokenDesc: '스테이킹으로 얻은 수익을 출금하지 않으면 복리 수익이 발생하며, 1SOLFI = 1SOL의 비율로 SOLFI 토큰이 지급됩니다. SolFi 토큰(SOLFI)은 플랫폼의 기능 토큰으로 사용되며, AI 서비스 및 커뮤니티 거버넌스에 참여할 수 있습니다. 미래에 매우 가치 있는 자산이 될 것입니다.',
        tokenList1: '복리 혜택을 받을 수 있나요',
        tokenList2: '획득한 복리 수익',
        tokenList3: '출금 가능한 복리 수익',
        yes: '예',
        no: '아니요',
        back: '스테이킹으로 돌아가기',
        money: '금액',
        timeStart: '스테이킹 시작 시간',
        timeEnd: '만료 시간',
        cancel: '자동 갱신 취소',
        more: '더보기...',
        max: '최대',
        quotaRemain: '잔액',
        usable: '스테이킹 가능',
        redeemed: '상환 완료',
        redeeming: '상환 중...',
        staking: '스테이킹 중...'
    },
    award: {
        communityDesc: '커뮤니티 발전에 기여한 구성원은 특별한 커뮤니티 보상을 받습니다.',
        communityList1: '획득한 보상',
        communityList2: '출금한 보상',
        communityList3: '출금 가능한 보상',
        communityBut: '출금',
        record: '보상 기록',
        listType1: '직접 추천 보상',
        listType2: '팀 보상',
        listType3: '동일 레벨 보상',
        listType4: '배당 보상',
        total: '총 보상'
    },
    top: {
        tokenDesc: 'SolFi 토큰(SOLFI)은 플랫폼의 기능 토큰으로 사용되며, AI 서비스 및 커뮤니티 거버넌스에 참여할 수 있습니다. 미래에 매우 가치 있는 자산이 될 것입니다.',
        price: '가격',
        allAmount: '총 공급량',
        market: '시가총액',
        my: '내 SOLFI',
        balance: '지갑 잔액',
        buy: '스왑',
        approve: 'SOL 승인',
        approveTop: 'SOLFI 승인',
        earn: '획득한 수익',
        getYet: '출금 가능',
        get: '출금'
    },
    ido: {
        turn: '현재 라운드',
        all: 'IDO 총량',
        price: '가격',
        limit: '구독 한도',
        ps: '해제 방식: []개월 잠금 후, 12개월 동안 선형 해제',
        my: '내 구독',
        nobuy: '구독하지 않음',
        balance: '잔액',
        custom: '예상 획득량',
        buy: '지금 구독하기',
        buyYet: '구독 완료',
        record: '구독 기록',
        buyTrun: '구독 라운드',
        buyUsdt: 'SOL 구독',
        getTop: 'SOLFI 획득',
        unlock: '다음 해제 시간',
        unlockYet: '해제된 SOLFI',
        claimable: '출금 가능한 SOLFI',
        get: '출금',
        nodataText: '구독 기록 없음'
    },
    user: {
        desc: 'STAKOS를 공유하고, 더 많은 사람들을 도와 블록체인 분야에서 높은 수익을 얻으세요.',
        joinTime: '등록 시간',
        memberTeam: '팀 멤버',
        memberDirect: '직접 추천 멤버',
        teamLp: '팀 스테이킹',
        myLink: '내 초대 링크',
        myTeam: '내 팀',
        personage: '개인 스테이킹',
        cur: '현재',
        lookDown: '하위 보기',
        copy: '복사',
        info: '개인 정보'
    }
}

export default ko;
